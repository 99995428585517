import gql from 'graphql-tag';

export const BLOCK_SOCIAL_FEED_MUTATION = gql`
  mutation blockSocialFeed($id: ID!) {
    blockSocialFeed(id: $id) {
      id
      status
    }
  }
`;

export const RESTORE_SOCIAL_FEED_MUTATION = gql`
  mutation restoreSocialFeed($id: ID!) {
    restoreSocialFeed(id: $id) {
      id
    }
  }
`;

export const BLOCK_SOCIAL_FEED_COMMENT_MUTATION = gql`
  mutation blockSocialFeedComment($id: ID!) {
    blockSocialFeedComment(id: $id) {
      id
      status
    }
  }
`;

export const RESTORE_SOCIAL_FEED_COMMENT_MUTATION = gql`
  mutation restoreSocialFeedComment($id: ID!) {
    restoreSocialFeedComment(id: $id) {
      id
    }
  }
`;
