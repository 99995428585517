import { ApolloClient, NormalizedCacheObject } from 'apollo-boost';
import {
  BLOCK_SOCIAL_FEED_COMMENT_MUTATION,
  BLOCK_SOCIAL_FEED_MUTATION,
  RESTORE_SOCIAL_FEED_COMMENT_MUTATION,
  RESTORE_SOCIAL_FEED_MUTATION,
} from '@/domain/socialFeed/queries/mutation';

export class SocialFeedService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  public async blockSocialFeed(id: string): Promise<boolean> {
    const { data } = await this.apollo.mutate({
      mutation: BLOCK_SOCIAL_FEED_MUTATION,
      variables: {
        id,
      },
    });

    if (data.errors?.length > 0) {
      throw data.errors[0];
    }

    return true;
  }

  public async restoreSocialFeed(id: string): Promise<boolean> {
    const { data } = await this.apollo.mutate({
      mutation: RESTORE_SOCIAL_FEED_MUTATION,
      variables: {
        id,
      },
    });

    if (data.errors?.length > 0) {
      throw data.errors[0];
    }

    return true;
  }

  public async blockSocialFeedComment(id: string): Promise<boolean> {
    const { data } = await this.apollo.mutate({
      mutation: BLOCK_SOCIAL_FEED_COMMENT_MUTATION,
      variables: {
        id,
      },
    });

    if (data.errors?.length > 0) {
      throw data.errors[0];
    }

    return true;
  }

  public async restoreSocialFeedComment(id: string): Promise<boolean> {
    const { data } = await this.apollo.mutate({
      mutation: RESTORE_SOCIAL_FEED_COMMENT_MUTATION,
      variables: {
        id,
      },
    });

    if (data.errors?.length > 0) {
      throw data.errors[0];
    }

    return true;
  }
}
