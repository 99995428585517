



























































































import Vue from 'vue';
import { Option, Select, Table, TableColumn } from 'element-ui';
import {
  SocialFeedAPIResponse,
  SocialFeedConnection,
  SocialFeedEdge,
  SocialFeedFilterParam,
  SocialFeedsRequestParam,
  SocialFeedState,
  SocialFeedType,
} from '@/domain/socialFeed/model/socialFeed';
import { isHostAdmin } from '@/env';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { GET_SOCIAL_FEEDS_QUERY } from '@/domain/socialFeed/queries/query';
import SocialFeedListFilter from '@/domain/socialFeed/components/SocialFeedListFilter.vue';
import SocialFeedCard from '@/domain/socialFeed/components/SocialFeedCard.vue';
import { SocialFeedService } from '@/domain/socialFeed/service/SocialFeedService';
import { apolloClient } from '@/apolloClient';

const socialFeedService = new SocialFeedService(apolloClient);

export default Vue.extend({
  name: 'SocialFeedList',
  components: {
    SocialFeedCard,
    SocialFeedListFilter,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      disabled: false,
      isHostAdmin: isHostAdmin(),
      page: this.$route.query?.page || 1,
      size: this.$route.query?.pageSize || 10,
      socialFeedContainer: { socialFeeds: { edges: [] as SocialFeedEdge[] } },
      totalRows: 0,
      searchFilter: {
        statusIn: [SocialFeedState.ACTIVE, SocialFeedState.INACTIVE] || null,
        contentsLike: this.$route.query?.contentsLike?.toString() || null,
        typeIdIn:
          [
            SocialFeedType.COMMON,
            SocialFeedType.REVIEW,
            SocialFeedType.GATHER,
          ] || null,
        writerId: Number(this.$route.query?.writerId) || null,
        id: Number(this.$route.query?.id) || null,
        targetProductId: Number(this.$route.query?.targetProductId) || null,
      } as SocialFeedFilterParam,
      filter: {} as SocialFeedFilterParam,
      blockSocialFeedModal: false,
      blockSocialFeedModalData: {
        id: '0',
        contents: '',
        status: SocialFeedState.ACTIVE,
      },
    };
  },
  computed: {
    SocialFeedState() {
      return SocialFeedState;
    },
    socialFeeds(): SocialFeedAPIResponse[] {
      if (this.socialFeedContainer.socialFeeds.edges) {
        return this.socialFeedContainer.socialFeeds.edges.map(
          edge => edge.node
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    openBlockModal(row: SocialFeedAPIResponse): void {
      this.blockSocialFeedModal = true;
      this.blockSocialFeedModalData.id = row.id.toString();
      this.blockSocialFeedModalData.contents = row.contents;
    },
    async blockSocialFeed(id: string): Promise<void> {
      this.blockSocialFeedModal = false;
      try {
        this.disabled = true;
        await socialFeedService.blockSocialFeed(id);
        this.$notify({
          title: '차단 성공',
          type: 'success',
          message: `피드가 차단되었습니다.`,
          position: 'bottom-right',
        });
        await this.$apollo.queries.socialFeedContainer.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '차단 실패',
          type: 'warning',
          message: `피드 차단 실패 (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    async restoreSocialFeed(id: string): Promise<void> {
      try {
        this.disabled = true;
        await socialFeedService.restoreSocialFeed(id);
        this.$notify({
          title: '차단 해제 성공',
          type: 'success',
          message: `피드 차단이 해제되었습니다.`,
          position: 'bottom-right',
        });
        await this.$apollo.queries.socialFeedContainer.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '차단 해제 실패',
          type: 'warning',
          message: `피드 차단 해제 실패 (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    async resetFilter(): Promise<void> {
      this.page = 1;
      this.size = 10;
      this.initSearchFilter();
      await this.getList();
    },
    initSearchFilter(): void {
      this.searchFilter = {
        statusIn: [SocialFeedState.ACTIVE, SocialFeedState.INACTIVE],
        contentsLike: null,
        typeIdIn: [
          SocialFeedType.COMMON,
          SocialFeedType.REVIEW,
          SocialFeedType.GATHER,
        ],
        writerId: null,
        id: null,
        targetProductId: null,
      };
    },
    async getList(): Promise<void> {
      try {
        this.disabled = true;
        this.filter = {
          statusIn: this.searchFilter.statusIn,
          contentsLike: this.searchFilter.contentsLike || null,
          writerId: this.searchFilter.writerId?.toString() || null,
          targetProductId:
            this.searchFilter.targetProductId?.toString() || null,
          id: this.searchFilter.id?.toString() || null,
          typeIdIn: this.searchFilter.typeIdIn || null,
        };
        await this.$apollo.queries.socialFeedContainer.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `피드 목록을 불러오는데 실패하였습니다. (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    pageClick(bvEvent: never, page: number): void {
      if (
        this.$route.path !==
        `/social-feed/list?page=${page}&pageSize=${this.size}`
      ) {
        this.$router.push(
          `/social-feed/list?page=${page}&pageSize=${this.size}`
        );
      }
    },
  },
  apollo: {
    socialFeedContainer: {
      query: GET_SOCIAL_FEEDS_QUERY,
      variables(): SocialFeedsRequestParam {
        return {
          page: +this.page,
          size: +this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError): void {
        this.disabled = false;
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          socialFeedContainer: { socialFeeds: SocialFeedConnection };
        }>
      ): void {
        this.disabled = false;
        this.totalRows = result.data.socialFeedContainer.socialFeeds.totalCount;
      },
      update: data => data.socialFeedContainer,
    },
  },
});
