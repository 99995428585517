




























































import Vue from 'vue';
import { SocialFeedState } from '../model/socialFeed';
import { SocialFeedService } from '@/domain/socialFeed/service/SocialFeedService';
import { apolloClient } from '@/apolloClient';

const socialFeedService = new SocialFeedService(apolloClient);

export default Vue.extend({
  name: 'SocialFeedCommentCard',
  components: {},
  props: ['disabled', 'socialFeedComment'],
  data() {
    return {
      commentDisabled: false,
    };
  },
  computed: {
    SocialFeedState() {
      return SocialFeedState;
    },
  },
  watch: {},
  methods: {
    async blockSocialFeedComment(id: string): Promise<void> {
      try {
        this.commentDisabled = true;
        await socialFeedService.blockSocialFeedComment(id);
        this.$notify({
          title: '차단 성공',
          type: 'success',
          message: `댓글이 차단되었습니다.`,
          position: 'bottom-right',
        });
        this.socialFeedComment.status = SocialFeedState.INACTIVE;
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '차단 실패',
          type: 'warning',
          message: `차단 실패 (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.commentDisabled = false;
      }
    },
    async restoreSocialFeedComment(id: string): Promise<void> {
      try {
        this.commentDisabled = true;

        await socialFeedService.restoreSocialFeedComment(id);
        this.$notify({
          title: '차단 해제 성공',
          type: 'success',
          message: `피드 차단이 해제되었습니다.`,
          position: 'bottom-right',
        });
        this.socialFeedComment.status = SocialFeedState.ACTIVE;
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '차단 해제 실패',
          type: 'warning',
          message: `피드 차단 해제 실패 (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.commentDisabled = false;
      }
    },
    convertReviewStatusColor(status: SocialFeedState): string {
      if (status === SocialFeedState.ACTIVE) {
        return 'primary';
      } else if (status === SocialFeedState.INACTIVE) {
        return 'success';
      } else {
        return 'danger';
      }
    },
    convertReviewStatusText(status: SocialFeedState): string {
      switch (status) {
        case SocialFeedState.ACTIVE:
          return '활성';
        case SocialFeedState.INACTIVE:
        default:
          return '비활성';
      }
    },
  },
});
