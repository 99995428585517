import { PageInfo } from '@frientrip/domain';

export enum SocialFeedState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export enum SocialFeedType {
  COMMON = '0',
  REVIEW = '1',
  GATHER = '2',
}

export interface ListingProduct {
  // 아이디
  id: string;
  // 제목
  title: string;
  //  썸네일
  headerContent?: {
    content: {
      thumbnail: string;
      uri: string;
    };
  };
}

enum ContentType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  HTML = 'HTML',
  TEXT = 'TEXT',
}

interface Content {
  id: string;
  type: ContentType;
  width: number;
  height: number;
  uri: string;
  contentId: string;
}

export interface User {
  id: number | null;
  nickname: string | null;
}

export interface UserProfile {
  id: number | null;
  nickname: string | null;
}

interface TargetOfSocialFeed {
  product: ListingProduct;
}
interface SocialFeedTypeApiResponse {
  id: string;
  status: SocialFeedState;
  name: string;
  indexNo: number;
}
export interface SocialFeedFilterParam {
  contentsLike?: string | null;
  statusIn?: SocialFeedState[] | null;
  typeIdIn?: string[] | null;
  writerId?: string | null;

  targetProductId?: string | null;

  id?: string | null;
}

export interface SocialFeedsRequestParam {
  page?: number;
  size?: number;
  filter?: SocialFeedFilterParam;
}

export interface SocialFeedEdge {
  node: SocialFeedAPIResponse;
  cursor: string;
}

export interface SocialFeedConnection {
  edges: SocialFeedEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface SocialFeedCommentAPIResponse {
  id: string;
  status: SocialFeedState;
  likeCount: number;
  reportCount: number;
  contents: string;
  writerProfile: UserProfile;
}

export interface SocialFeedAPIResponse {
  id: string;
  attachmentContents: Content[];
  attachmentCount: number;
  contents: string;
  createdAt: Date;
  likeCount: number;
  reportCount: number;
  commentCount: number;
  comments: SocialFeedCommentAPIResponse;
  reported: boolean;
  status: SocialFeedState;
  target?: TargetOfSocialFeed;
  type: SocialFeedType;
  updatedAt: Date;
  writerProfile: UserProfile;
}
