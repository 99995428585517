import gql from 'graphql-tag';
export const SOCIAL_FEED_CONTENT_FRAGMENT = gql`
  fragment contentFields on Content {
    id
    type
    width
    height
    uri
    thumbnail(fetchFormat: AUTO, crop: FILL, height: 150)
  }
`;
export const GET_SOCIAL_FEEDS_QUERY = gql`
  ${SOCIAL_FEED_CONTENT_FRAGMENT}
  query getSocialFeedQuery(
    $filter: SocialFeedFilterInput
    $page: Int
    $size: Int
  ) {
    socialFeedContainer {
      socialFeeds(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            status
            contents
            type {
              name
            }
            target {
              product {
                id
                title
                headerContents {
                  content {
                    ...contentFields
                  }
                }
              }
            }
            commentCount
            comments {
              id
              status
              contents
              writerProfile {
                id
                nickname
              }
              likeCount
              reportCount
              createdAt
              updatedAt
            }
            likeCount
            attachmentCount
            reportCount
            attachmentContents {
              ...contentFields
            }
            writerProfile {
              id
              nickname
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
