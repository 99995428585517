

















































































import { SocialFeedState } from '@/domain/socialFeed/model/socialFeed';
import Vue from 'vue';

export default Vue.extend({
  name: 'SocialFeedListFilter',
  components: {},
  props: ['disabled', 'searchFilter'],
  data() {
    return {
      statusOptions: [
        { value: SocialFeedState.ACTIVE, text: '활성' },
        { value: SocialFeedState.INACTIVE, text: '비활성' },
      ],
      typeOptions: [
        { value: '0', text: '일반' },
        { value: '1', text: '리얼후기' },
        { value: '2', text: '같이해요' },
      ],
    };
  },
  methods: {
    search(): void {
      this.$emit('getList');
    },
  },
});
