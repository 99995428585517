

























































































import Vue from 'vue';
import {
  ListingProduct,
  SocialFeedCommentAPIResponse,
  SocialFeedState,
} from '@/domain/socialFeed/model/socialFeed';
import { getDomainName } from '@/env';
import SocialFeedCommentCard from '@/domain/socialFeed/components/SocialFeedCommentCard.vue';
import { Option, Select, Table, TableColumn } from 'element-ui';

export default Vue.extend({
  name: 'SocialFeedCard',
  components: {
    SocialFeedCommentCard,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: ['socialFeed', 'disabled'],
  data() {
    return {
      SocialFeedState: SocialFeedState,
      isSocialFeedCommentOpen: false,
    };
  },
  computed: {
    socialFeedComments(): SocialFeedCommentAPIResponse[] {
      if (this.socialFeed.comments) {
        return this.socialFeed.comments;
      } else {
        return [];
      }
    },
    searchDetailToggleIcon(): string {
      if (!this.$data.isSocialFeedCommentOpen) {
        return 'iconDownArrow';
      } else {
        return 'iconTopArrow';
      }
    },
    fripUrl(): string {
      return getDomainName();
    },
    targetProduct() {
      if (this.socialFeed.target) {
        return {
          id: this.socialFeed.target?.product?.id.toString(),
          title: this.socialFeed.target?.product?.title.toString(),
          headerContents: {
            content: {
              thumbnail:
                this.socialFeed.target?.product?.headerContents[0]?.content
                  .thumbnail || '',
              uri:
                this.socialFeed.target?.product?.headerContents[0]?.content
                  .uri || '',
            },
          },
        } as ListingProduct;
      }
      return null;
    },
  },
  methods: {
    toggleSocialFeedComment(): void {
      this.$data.isSocialFeedCommentOpen = !this.$data.isSocialFeedCommentOpen;
    },
    convertSocialFeedStatusColor(status: SocialFeedState): string {
      if (status === SocialFeedState.ACTIVE) {
        return 'primary-secondary';
      } else if (status === SocialFeedState.INACTIVE) {
        return 'success';
      } else {
        return 'danger';
      }
    },
    convertSocialFeedStatusText(status: SocialFeedState): string {
      switch (status) {
        case SocialFeedState.ACTIVE:
          return '활성';
        case SocialFeedState.INACTIVE:
        default:
          return '비활성';
      }
    },
    openOriginImage(uri: string): void {
      window.open(uri);
    },
  },
});
